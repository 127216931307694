import { CONTENTFUL_IMAGE_BASE_URL } from "../constants";

export interface ImageOptions {
  height?: number;
  width?: number;

  /**
   * https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/change-the-resizing-behavior
   */
  fit?: "pad" | "fill" | "scale" | "crop" | "thumb";

  /**
   * https://www.contentful.com/developers/docs/references/images-api/#/reference/changing-formats/image-format
   */
  fm?: "jpg" | "png" | "webp" | "gif" | "avif";

  /**
   * https://www.contentful.com/developers/docs/references/images-api/#/reference/image-manipulation/quality
   */
  q?: number;
}

/**
 * See:
 * https://www.contentful.com/developers/docs/references/images-api/#/reference/changing-formats/avif
 */
export function getContentfulImageUrl(
  url: string,
  { width, height, fit, fm = "webp", q = 90 }: ImageOptions,
): string {
  const searchParams = new URLSearchParams();
  if (width) {
    const MAX_WIDTH = 3999;
    searchParams.set("w", String(Math.min(MAX_WIDTH, width)));
  }
  if (height) {
    searchParams.set("h", String(height));
  }
  if (fit) {
    searchParams.set("fit", fit);
  }
  if (fm) {
    searchParams.set("fm", fm);
  }
  if (q) {
    searchParams.set("q", String(q));
  }

  return url
    .replace("https://images.ctfassets.net", CONTENTFUL_IMAGE_BASE_URL)
    .concat("?")
    .concat(searchParams.toString());
}
