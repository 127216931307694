"use client";

import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";

const LocaleStringContext = createContext<string | undefined>(undefined);

export const LocaleStringContextProvider = (
  args: React.ComponentProps<typeof LocaleStringContext.Provider>,
) => <LocaleStringContext.Provider {...args} />;

export const useLocaleStringContext = (): string => {
  const context = useContext(LocaleStringContext);

  assertNotNullish(
    context,
    "useLocaleStringContext was used outside of Provider",
  );

  return context;
};
