"use client";

import { createContext, useContext } from "react";

export type ImageLoading = "lazy" | "eager";

const ImageLoadingContext = createContext<ImageLoading>("lazy");

export const ImageLoadingContextProvider = (
  args: React.ComponentProps<typeof ImageLoadingContext.Provider>,
) => <ImageLoadingContext.Provider {...args} />;

export const useImageLoading = (): ImageLoading => {
  return useContext(ImageLoadingContext);
};
