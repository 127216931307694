export const FLAGS_API_PREFIX = "https://resources.redbull.com/icons/flags/v2/";

export const CARTOONS_API_URL = "https://pcms.redbull.com/public/graphql";
export const CARTOONS_COLLECTION_SLUG = "pcms-cartoon-collection";

/**
 * Asset "Proxy" Routes
 *
 * These routes are necessary because we have had issues in the past with
 * these upstream sources being geo-blocked in certain countries with a
 * heavy-handed approach to net-neutrality (*cough China cough*).
 *
 * In the front-end, we have to re-write image URLs to use these proxy
 * routes instead of the source URL, so that the browser never makes a
 * direct connection with any of these sources.
 */
export const CONTENTFUL_IMAGE_BASE_URL = `https://www.redbull.com/energydrink/v1/resources/contentful/images`;
export const CONTENTFUL_VIDEO_BASE_URL = `https://www.redbull.com/energydrink/v1/resources/contentful/videos`;
