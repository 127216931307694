"use client";

import { KickerKind } from "../components/kicker/kicker";
import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";

const KickerContext = createContext<KickerKind | undefined>(undefined);

export const KickerContextProvider = (
  args: React.ComponentProps<typeof KickerContext.Provider>,
) => <KickerContext.Provider {...args} />;

export const useKickerContext = () => {
  const context = useContext(KickerContext);

  assertNotNullish(context, "useKickerContext was used outside of Provider");

  return context;
};
